.ScrollbarsCustom-TrackY{
    background-color: transparent !important;
    margin-right:2px;
    top:2px !important;
    height:auto !important;
    bottom: 2px !important;
}

div{
    outline:none;
}

.tippy-tooltip{
    padding:0px !important;
    border:1px solid gray;
}